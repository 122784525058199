<template>
  <section id="card-actions">
    <b-row>

      <!-- card actions -->
      <b-col cols="4">
        <b-card-actions
          ref="reinitall"
          @refresh="syncAction('reinitall')"
        >
          <statistic-card-vertical
            icon="DatabaseIcon"
            color="info"
            statistic="Server Init"
            statistic-title="Queries"
          />
        </b-card-actions>
      </b-col>
      <b-col cols="4">
        <b-card-actions
          ref="clearcache"
          @refresh="syncAction('clearcache')"
        >
          <statistic-card-vertical
            icon="EyeOffIcon"
            color="info"
            statistic="Clear"
            statistic-title="cache"
          />
        </b-card-actions>
      </b-col>
      <b-col cols="4">
        <b-card-actions
          action-collapse
          title="Server Info"
        >
          <!-- body -->
          <b-card-body>
            <b-card-text>SAP Server: {{ serverInfo.SAPServer }}<br>
              SAP Company: {{ serverInfo.SAPCompany }}<br>
              SAP User: {{ serverInfo.SAPUser }}<br>
              App DB: {{ serverInfo.AppDB }}<br>
              App Server: {{ serverInfo.AppServer }}<br>
              Maintenance: {{ serverInfo.Maintenance }}<br>
              JAPI: {{ serverInfo.JAPI }}<br>
              JSREPORT: {{ serverInfo.JSREPORT }}<br>
              CUPS: {{ serverInfo.CUPS }}<br>
              CRXGATEWAY: {{ serverInfo.CRXGATEWAY }}<br>
              Server Language: {{ serverInfo.ServerLang }}<br>
              SAP Type: {{ serverInfo.SAPType }}<br>
              Server: {{ serverInfo.Server }}</b-card-text>
          </b-card-body>
        </b-card-actions>
      </b-col>
      <b-col cols="6">
        <b-card-actions
          ref="companyinfo"
          action-collapse
          title="Company Default"
        >
          <b-form @submit.prevent="onSubmitCompanyInfo">
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Company Name"
                  label-for="CompanyName"
                  label-cols-md="4"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="HomeIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="CompanyName"
                      v-model="companyInfo.CompnyName"
                      placeholder="Company Name"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Company Address"
                  label-for="CompnyAddr"
                  label-cols-md="4"
                >
                  <b-form-textarea
                    id="CompnyAddr"
                    v-model="companyInfo.CompnyAddr"
                    placeholder="Address"
                    rows="3"
                    max-rows="8"
                  />
                </b-form-group>

              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="VAT Number"
                  label-for="taxid"
                  label-cols-md="4"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="InfoIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="taxid"
                      v-model="companyInfo.TaxIdNum"
                      placeholder="VAT Number"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Email"
                  label-for="hi-email"
                  label-cols-md="4"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MailIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="hi-email"
                      v-model="companyInfo.E_Mail"
                      type="email"
                      placeholder="Email"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Phone"
                  label-for="hi-phone"
                  label-cols-md="4"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="PhoneIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="hi-phone"
                      v-model="companyInfo.Phone1"
                      placeholder="Phone"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Currency"
                  label-for="Currency"
                  label-cols-md="4"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="DollarSignIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="Currency"
                      v-model="companyInfo.MainCurncy"
                      placeholder="Currency"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Default Base Path"
                  label-for="DefaultBasePath"
                  label-cols-md="4"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ExternalLinkIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="DefaultBasePath"
                      v-model="companyInfo.DefaultBasePath"
                      placeholder="Default Base Path"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Logo"
                  label-for="Logo"
                  label-cols-md="4"
                >
                  <b-form-textarea
                    id="Logo"
                    v-model="companyInfo.Logo"
                    placeholder="Logo"
                    rows="3"
                    max-rows="8"
                  />
                </b-form-group>

              </b-col>
              <!-- submit and reset -->
              <b-col offset-md="4">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                >
                  Reset
                </b-button>
              </b-col>
            </b-row>

          </b-form>
        </b-card-actions>
      </b-col>
      <b-col cols="6">
        <b-card-actions
          ref="licensing"
          action-collapse
          title="License"
        >
          <b-form @submit.prevent="onSubmitLicense">
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="License Key"
                  label-for="lickey"
                  label-cols-md="4"
                >
                  <b-form-textarea
                    id="lickey"
                    v-model="forms.licenseKey"
                    placeholder="License Key"
                    rows="3"
                    max-rows="8"
                  />
                </b-form-group>

              </b-col>
              <!-- submit and reset -->
              <b-col offset-md="4">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                >
                  Reset
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card-actions>
        <b-card-actions
          ref="modules"
          action-collapse
          title="Moduli Attivati"
        >
          <b-row>
            <b-col cols="12">
              <b-list-group
                v-for="(m, idx) in licenseInfo.modules"
                :key="idx"
              >
                <b-list-group-item
                  :variant="`${resolveVariantColor(m.active)}`"
                  :href="`/${m.name.toLowerCase()}`"
                >{{ m.name }}</b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
        </b-card-actions>
      </b-col>

    </b-row>
  </section>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import {
  BRow, BCol, BButton, BForm, BFormTextarea, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BListGroup, BListGroupItem, BCardBody, BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import axios from '@axios'

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BButton,
    StatisticCardVertical,
    BForm,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BListGroup,
    BListGroupItem,
    BCardText,
    BCardBody,
  },
  directives: {
    Ripple,
  },
  setup() {
    const serverInfo = ref({})
    const licenseInfo = ref({})
    const companyInfo = ref({})

    onMounted(() => {
      axios
        .get(`/base/getserverinfo`)
        .then(response => {
          serverInfo.value = response.data.Data
        })
        .catch(error => {
        })
      axios
        .get(`/base/getlicinfo`)
        .then(response => {
          licenseInfo.value = response.data.Data
        })
        .catch(error => {
        })
      axios
        .get(`/base/company/info`)
        .then(response => {
          console.log(response.data)
          companyInfo.value = response.data.company
        })
        .catch(error => {
        })
    })

    return {
      serverInfo,
      licenseInfo,
      companyInfo,
    }
  },
  data() {
    return {
      forms: {
        licenseKey: '',
        standardBaseUrl: '',
      },
    }
  },
  methods: {
    resolveVariantColor(isactive) {
      if (isactive == 'Y') {
        return 'success'
      }
      return 'danger'
    },
    onSubmitCompanyInfo() {
      axios
        .post(`/base/savecompanyinfo`, {
          companyInfo: this.companyInfo,
        })
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Company Info Updated',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: ``,
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Company Info Error',
              icon: 'CoffeeIcon',
              variant: 'error',
              text: ``,
            },
          })
        })
      // this.$router.go()
    },
    onSubmitLicense() {
      axios
        .post(`/base/savelicense`, {
          licenseKey: this.forms.licenseKey,
        })
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'License Key updated',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: ``,
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'License Key not valid',
              icon: 'CoffeeIcon',
              variant: 'error',
              text: ``,
            },
          })
        })
      // this.$router.go()
      // console.log(props.roles.roles[0])
      // console.log(userData.value.role)
      // const permissions = props.roles.roles.filter(x => x.name == userData.value.role)[0]
      // console.log(permissions)
      // tjh.dispatch('app-user/addUser', {
      //   userData: userData.value,
      //   permissions,
      // })
      //   .then(() => {
      //     emit('refetch-data')
      //     emit('update:is-add-new-user-sidebar-active', false)
      //   })
    },
    //   syncAction(action) {
    //     this.$refs[action].showLoading = true
    //     this.$http.defaults.timeout = 0
    //     this.$http
    //       .get(`/auth/${action}`, { timeout: -1 })
    //       .then(response => {
    //         this.$refs[action].showLoading = false
    //         this.$http.defaults.timeout = 10000
    //       })
    //       .catch(error => {
    //         console.log(error)
    //         this.$refs[action].showLoading = false
    //         this.$http.defaults.timeout = 10000
    //       })
    //   },
    // },
    syncAction(action) {
      const modules = ["wssync", "wsprj", "wscrm", "api", "wsmes", "wsedi", "wssrvc"]
      // eslint-disable-next-line no-restricted-syntax
      for (const module of modules) {
        this.$refs[action].showLoading = true
        this.$http.defaults.timeout = 0
        this.$http
          .get(`/${module}/${action}`, { timeout: -1 })
          .then(response => {
            this.$refs[action].showLoading = false
            this.$http.defaults.timeout = 10000
          })
          .catch(error => {
            console.log(error)
            this.$refs[action].showLoading = false
            this.$http.defaults.timeout = 10000
          })
      }
      // this.$http
      //   .get(`/wssync/${action}`, { timeout: -1 })
      //   .then(response => {
      //     this.$refs[action].showLoading = false
      //     this.$http.defaults.timeout = 10000
      //   })
      //   .catch(error => {
      //     console.log(error)
      //     this.$refs[action].showLoading = false
      //     this.$http.defaults.timeout = 10000
      //   })
    },
  },
}
</script>
